// TimeCounter.js
import React from 'react';
import { Text, Image } from 'react-konva';
import useImage from 'use-image';

const TimeCounter = ({ time, isTakeDice }) => {
    const [diceImage] = useImage('./assets/sprites/Dice1.png'); // Замените путь на ваш
  return (
    <>
        <Image
        image={diceImage}
        x={30}
        y={10}
        width={40}
        height={40}
        opacity={isTakeDice ? 1 : 0.4}
        />
        <Text
        text={`${time}`}
        x={75}
        y={20}
        fontSize={24}
        fill="white"
        fontFamily='MonBold'
    />
    </>
    
  );
};

export default TimeCounter;
