
import { AliveScope } from 'react-activation';
import './App.css';
import AppRouter from './pages/AppRouter';
import Home from './pages/Home/Home';
import React from 'react'
import { BrowserRouter } from 'react-router-dom';
const tg = window?.Telegram?.WebApp;

function App() {

  if (tg) {
      tg.expand();
     
  }

  return (
    <AliveScope>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      </AliveScope>
  );
}

export default App;
