 import {FC, useContext, useState} from "react";

import styles from "./styles.module.css";
import InvitesList from "../../compotents/Pages/Invites/InvitesList/InvitesList.jsx";
import InvitesModal from "../../compotents/Pages/Invites/InvitesModal/InvitesModal.jsx";
import { Context } from "../../index.js";
import { observer } from "mobx-react-lite";
import { clearClaimRefferal } from "../../http/userAPI.js";
import { Link } from "react-router-dom";


export const Invites = observer(() => {
    const name = 'Vladimir Dzen'
    const image = './assets/images/userr.png';
    const {user} = useContext(Context);

    const [modal, setModal] = useState(false);
    
    function setModalOpen() {
        setModal(prevModal => !prevModal);
    }

    async function clearClaim() {
        try {
            const answer = await clearClaimRefferal(user.id, user.claimcoins);
            if(answer) {
                user.setCoins(user.coins + user.claimcoins);
                user.setClaimcoins(0);
                
                console.log(user.coins)
            } 
        }
        catch(e) {
            console.log(e);
        }
    }
    return (
        <div className={styles.container}>
            <Link to={'/leaders'} className={styles.userHeader}>
                <img src={user.photo || image} alt="user"></img>
                <span>{window?.Telegram?.WebApp?.initDataUnsafe?.user?.username || 'John'}</span>
            </Link>
            <div className={styles.buttonLink}>
                <button className={styles.button} onClick={() => {setModalOpen()}}>
                    Your link
                </button>
            </div>
            <p className={styles.text}>Score 10% from friends
            Get a ticket for each friend</p>
            {
                user.claimcoins > 0 ?
                <button className={styles.rewardsButton} onClick={clearClaim}>
                    Claim friend rewards
                </button>
                :
                <></>
            }
            <InvitesList></InvitesList>
            <InvitesModal visible={modal} setVisible={setModalOpen}></InvitesModal>
        </div>
    );
})

