import { FC, useContext, useState } from "react";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { Context } from "../../../..";



const TasksItem= ({ action, exercise, link, statusTask, prise}) => {
    const [status, setStatus] = useState(statusTask);

    const {user} = useContext(Context);
    
    const handleStatusChange = () => {
        if (status === 0) {
            setStatus(1);
        } else if (status === 1) {
            setStatus(2);
        } 
    };

    const rootClasses = [styles.item];

    if(status === 1) {
        rootClasses.push(styles.active);
    } else
    if(status === 2) {
        rootClasses.push(styles.done);
    }

    function taskAction() {
        if(status === 0) {
            
        }
        if(status === 0) {
            user.setCoins(Number(user.coins) + Number(prise));
            window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('medium');
            setStatus(2);
            
        }
        if(status === 1) {
            setStatus(0);
            window.open('https://example.com', '_blank');
        }
        
    }

    function getStatusButton() {
        if(status === 0)
        {
            return (
                <button className={styles.claim}>
                    CLAIM
                </button>
            );
        }
        if(status === 1) {
            return (
                <button className={styles.work}>
                    {`+${prise}`}
                    <img alt="coin" className={styles.coin} src="./assets/images/Coin2.png"></img>
                </button>
            );
        }
        if(status === 2) {
            return (
                <button className={styles.claim} disabled>
                    {`DONE`}
                </button>
            );
        }
    }

    return (
        <a className={rootClasses.join(' ')}type="_blank" onClick={taskAction}>
            <div className={styles.textCont}>
                <span className={styles.action}>{action}</span>
                <span className={styles.name}>{exercise}</span>
            </div>
            <div className={styles.statusCont} onClick={handleStatusChange}>
                {getStatusButton()}
            </div>
        </a>
    );
}

export default TasksItem;