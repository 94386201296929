import React, { useEffect, useState } from 'react';
import { Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';

const BallImage = ({ y, isAnimation, isEnd, setIsEnd, isDestroy }) => {
  const [image] = useImage('./assets/sprites/Ball.png');
  const [scale, setScale] = useState(4);
  const [progressFall, setProgressFall] = useState(0)

  function getPosition() {
    return window.innerHeight / 2 + progressFall * 200 - 25 * scale;
  }
 
  useEffect(() => {
    if (isAnimation) {
      const duration = 500; 
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        setProgressFall(progress);
        const newScale = 6 - 5 * progress; 

        setScale(newScale);
       

        if (progress < 1) {
          requestAnimationFrame(animate);
          setIsEnd(true);
        } 
      };

      requestAnimationFrame(animate);
    }
    console.log(isAnimation)
  }, [isAnimation]);

  return (
    <KonvaImage
      image={image}
      x={!isAnimation ? window.innerWidth / 2 - 25 * 1 : window.innerWidth / 2 - 25 * scale} 
      y={!isAnimation ? y : scale === 1 ? y : getPosition()}
      width={50}
      height={50}
      scaleX={isAnimation ? scale : 1}
      scaleY={isAnimation ? scale : 1}
      shadowColor={isDestroy ? '#fff' : ""}
      shadowOffset={isDestroy ? 3 : 0}
      shadowBlur={isDestroy ? 4 : 0}
    />
  );
};

export default BallImage;
