import { FC, useState } from "react";
import styles from "./style.module.css";
import TasksItem from "../TaskItem/TaskItem";

const TasksList = () => {
    const [tasks, setTasks] = useState(
        [
        { action: 'Subscribe', title: 'Join our x community', status: 0, link: '#', prise: 100 },
        { action: 'Subscribe', title: 'Join our tg community', status: 1, link: '#', prise: 100 },
        { action: 'Subscribe', title: 'Join our insta community', status: 2, link: '#', prise: 100 },
    ]
    ) 

    // Сортируем заказы по статусу
    const sortedTasks = tasks.sort((a, b) => a.status - b.status);

    console.log(sortedTasks)

    return (
        <div className={styles.main}> 
            <ul className={styles.list}>
                {sortedTasks.map((el) => {
                    return (
                        <TasksItem key={el.title} action={el.action} exercise={el.title} link={el.link} statusTask={el.status} prise={el.prise}></TasksItem>
                    )
                })}
            </ul>
        </div>
    );
}

export default TasksList;
