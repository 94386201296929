import { FC, useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";
import { LeagueCard } from "../LeagueCard/index.js";
import { leaders } from "../../../../http/userAPI.js";
import { Context } from "../../../../index.js";

export const LeagueList = () => {
    const [users, setUsers] = useState([]); // Используем состояние для хранения пользователей
    const {user} = useContext(Context)

    useEffect(() => {
        const fetchLeaders = async () => {
            try {
                const usersData = await leaders(); // Получаем данных
             
                setUsers(usersData); // Обновляем состояние
                
            } catch (e) {
                console.log(e); // Обработка ошибок
            }
        };

        fetchLeaders(); // Вызываем функцию для загрузки данных
    }, []); // Зависимости

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>
                league score
            </h3>
            <div className={styles.list}>
                {users.map((el, idx) => (
                    <LeagueCard key={idx} position={idx + 1} nickname={el.username} score={el.coins} active={user.id === el.id ? true : false} />
                ))}
            </div>
            <div className={styles.active}>
                <LeagueCard active={true} className={styles.title} key={'you'} position={1000} nickname="TEST" score={1000} />
            </div>
        </div>
    );
};

export default LeagueList;
