import React, { useContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { KeepAlive } from 'react-activation';
import Home from './Home/Home';
import { authRoute } from '../routes/routes';
import { Leaders } from './Leaders/Leaders';
import { Invites } from './Invites/Invites';
import { Tasks } from './Tasks/Tasks';
import { NavigationMenu } from '../compotents/Pages/NavigationMenu/NavigationMenu';
import { login } from '../http/userAPI';
import { Context } from '..';
import Loading from '../compotents/Pages/Loading/Loading';
import CachedImages from './CachedImages';
import GameState from '../game/state/gameManager';

const tg = window?.Telegram?.WebApp;

function AppRouter() {

  const {user} = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    start(); 
  }, [])

  async function start() {
    try {
      //const data = await login(1042650482, 'query_id=AAEW9nIrAwAAABb2ciszmow4&user=%7B%22id%22%3A7171405334%2C%22first_name%22%3A%22MaksBot%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22botmaksimka2003%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1724318808&hash=b5d83b76ea7e8023794027e8b30fef729742171c2b901b0b8b91a8c6cddf86b2');
      const data = await login(Number(tg?.initDataUnsafe?.user?.id), tg?.initData);

      user.setId(data.id); 
      user.setCoins(data.coins);
      user.setPhoto(data.photo_url)
      user.setClaimcoins(data.claimcoins);
      console.log(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
    catch(e) {
      console.log(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }
  
  const imageRef = useRef(null); 
 
  return (
 <>
    {isLoading  ? <Loading></Loading> : 
    <>
    <CachedImages></CachedImages>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dekstop" element={<Home />} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path='/leaders' element = {<Leaders></Leaders>}></Route>
      <Route path='/invites' element={<Invites/>}></Route>
      <Route path='/tasks' element={<Tasks></Tasks>}></Route>
      {}

      {/* Логика авторизации
      {user.isAuth && authRoute.map(({ path, Component }) => (
        <Route key={path} path={path} element={
          <KeepAlive key={path}>
            <Component />
          </KeepAlive>
        } />
      ))} */}
    </Routes>
    
    <NavigationMenu></NavigationMenu>
    </>
    }
 </>
    
    
  );
}

export default AppRouter;
