import React, { useState, useEffect, useRef } from 'react';
import { Image as KonvaImage } from 'react-konva';

const BonusImage = ({ image, x, y, isDestroy, type }) => {
  const [scale, setScale] = useState(1);
  const animationRef = useRef(null);  // Reference to store the animation frame ID

  useEffect(() => {
    console.log(isDestroy)
    if (isDestroy) {
      const duration = 500;
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const newScale = Math.floor(1 - progress);

        setScale(newScale);

        if (progress < 1) {
          animationRef.current = requestAnimationFrame(animate);
        }
      };

      animationRef.current = requestAnimationFrame(animate);
    }

    // Cleanup the animation if the component unmounts or dependencies change
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isDestroy]);

  return (
    <KonvaImage
      image={image}
      x={x}
      y={y}
      width={43}
      height={43}
      scaleX={scale}
      scaleY={scale}
      shadowBlur={12}
    />
  );
};

export default BonusImage;
