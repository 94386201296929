import {FC, useContext, useState} from "react";

import styles from "./style.module.css";
import { Context } from "../../../..";
const tg = window?.Telegram?.WebApp;




export const InvitesModal = ({visible, setVisible}) => {

    const rootClasses = [styles.main];
    if(visible) {
        rootClasses.push(styles.active);
    }

    const [isClick, setIsClick] = useState(false);
    const link = `https://t.me/tobobobobo_bot?start=${tg?.initDataUnsafe?.user?.id}`

    const sendLink = () => {
        const url = `https://t.me/share/url?url=${encodeURIComponent(link)}`;
        window.open(url, '_blank');
      };

    function CopyLick() {
        setIsClick(true);
        navigator.clipboard.writeText(link).then(() => {
         
        }, (err) => {
          alert('Ошибка при копировании ссылки: ' + err);
        });

        setTimeout(() => {
            setIsClick(false);
        }, 600);
    }

    return (
        <div className={rootClasses.join(' ')}>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <span>Invite friends</span>
                        <img src="./assets/images/krest.png" onClick={() => setVisible()}></img>
                    </div>
                    <div className={styles.body}>
                        <button onClick={CopyLick}>{!isClick ? 'Copy invite link' : 'Coped'}</button>
                        <button onClick={sendLink}>Share invite link</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvitesModal