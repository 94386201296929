// PlatformImage.js
import React from 'react';
import { Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';

const PlatformImage = () => {
  const [image] = useImage('./assets/sprites/platform.png'); // Замените на правильный путь к вашему изображению платформы

  return (
    <KonvaImage
      image={image}
      x={0}
      y={window.innerHeight - 50} // Фиксируем платформу внизу экрана
      width={window.innerWidth}
      height={50} // Высота платформы
    />
  );
};

export default PlatformImage;
