import { useEffect, useState } from "react";

import Game from "../../compotents/GameCanvas/GameCanvas";
import styles from "./style.module.css";
import GameState from "../../game/state/gameManager";
import HomeCont from "../../compotents/Pages/MainLayout/MainLayout";
import GameOver from "../../compotents/Pages/GameOver/GameOver";

const Home = () => {
    const [zIndexCanvas, setZIndexCanvas] = useState(-2);
    const [state, setState] = useState(GameState.state);
    const [visible, setVisible] = useState(false);

    function setGameState(state) {
        setZIndexCanvas(state !== 1 ? 1000 : -2);
        setVisible(state === 2);
        GameState.setState(state);
    }

    useEffect(() => {
        // Устанавливаем обработчик изменения состояния
        GameState.onChange = (newState) => {
            setState(newState);
        };

        // Очищаем обработчик при размонтировании компонента
        return () => {
            GameState.onChange = null;
        }; 
        

    }, []);

    

    return (
        <>
            {
                GameState.state === 1 && 
                <div>
                    <HomeCont setGameState={setGameState}></HomeCont>
                </div>
            }
            <div>
                {state == 2 && 
                    <div className={styles.gameOverBanner}>
                        <GameOver setGameState={setGameState} setState={setState} visible={visible}/>
                    </div>}
            </div>
            <div className={styles.page} style={{ zIndex: zIndexCanvas }}>
                <Game setState={setState} state={state} setGameState={setGameState}></Game>
            </div>
        </>
    );
};

export default Home;
