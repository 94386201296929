import {FC, useContext, useEffect, useState} from "react";

import styles from "./style.module.css";
import { refs } from "../../../../http/userAPI";
import { Context } from "../../../..";



export const InvitesList = () => {
    // const friend = [
    //     {
    //         name:'Friend1',
    //         money:1000,
    //     },
    //     {
    //         name:'Friend1',
    //         money:10,
    //     },
    //     {
    //         name:'Friend1',
    //         money:100,
    //     },
    //     { 
    //         name:'Friend1',
    //         money:1000,
    //     },
    //     {
    //         name:'Friend1',
    //         money:10,
    //     },
    //     {
    //         name:'Friend1',
    //         money:100,
    //     },
    //     {
    //         name:'Friend1',
    //         money:1000,
    //     },
    //     {
    //         name:'Friend1',
    //         money:10,
    //     },
    //     {
    //         name:'Friend1',
    //         money:100,
    //     },
    //     {
    //         name:'Friend1',
    //         money:1000,
    //     },
    //     {
    //         name:'Friend1',
    //         money:10,
    //     },
    //     {
    //         name:'Friend1',
    //         money:100,
    //     },
    //     {
    //         name:'Friend1',
    //         money:1000,
    //     },
    //     {
    //         name:'Friend1',
    //         money:10,
    //     },
    //     {
    //         name:'Friend1',
    //         money:100,
    //     },
    //     {
    //         name:'Friend1',
    //         money:1000,
    //     },
    //     {
    //         name:'Friend1',
    //         money:10,
    //     },
    //     {
    //         name:'Friend1',
    //         money:100,
    //     },
    //     {
    //         name:'Friend1',
    //         money:1000,
    //     },
    //     {
    //         name:'Friend1',
    //         money:10,
    //     },
    //     {
    //         name:'Friend1',
    //         money:100,
    //     },
    //     {
    //         name:'Friend1',
    //         money:1000,
    //     },
    //     {
    //         name:'Friend1',
    //         money:10,
    //     },
    //     {
    //         name:'Friend1',
    //         money:100,
    //     },

    // ]

    const [friend, setFriends] = useState([]);
    const {user} = useContext(Context);

    useEffect(() => {
        const start = async () => {
            try {
                const answer = await refs(user.id);
                console.log(answer)
                const newFriends = answer.map(el => ({
                    name: el.referred.username,
                    money: el.referred.coins ? el.referred.coins * 0.2 : 0
                }));
                setFriends(newFriends);
            }
            catch(e) {
                console.log(e)
            }
        }
        start();
    }, [user.id]); 


    function sortByMoneyDescending(arr) {
        return arr.sort((a, b) => b.money - a.money);
    }

    console.log(sortByMoneyDescending(friend))

    return (
        <div className={styles.main}>
            <ul className={styles.list}>
            {sortByMoneyDescending(friend).map((el) => {
                return (
                    <li className={styles.item} key={el.name}>
                        <div className={styles.itemCont}>
                            <span>{el.name}</span>
                            <span>{`${Math.floor(el.money)}`}<img alt="coin" className={styles.coin} src="./assets/images/Coin2.png"></img></span>
                        </div>
                        
                    </li>
                )
            })}
            </ul>
                    
        </div>
    );
}

export default InvitesList