// HeartCounter.js
import React from 'react';
import { Image, Text } from 'react-konva';
import useImage from 'use-image';

const HeartCounter = ({ hearts }) => {
    const [heartImage] = useImage('./assets/sprites/Heart2.png'); // Замените путь на ваш
  return (
    <>
          <Image
            image={heartImage}
            x={window.innerWidth / 2 - 22.5}
            y={10}
            width={40}
            height={40}
          />
          <Text
            text={`${hearts}`}
            x={window.innerWidth / 2 + 22.5}
            y={20}
            fontSize={24}
            fill="white"
            fontFamily='MonBold'
            />
        </>
  );
};

export default HeartCounter;
