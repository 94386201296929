import React, { useEffect } from "react";

const CachedImages = () => {

    const imagesToCache = [
        "/assets/sprites/test.png",
        "/assets/images/image1.jpg",
        "/assets/images/image2.jpg",
        // Добавьте остальные пути к изображениям
      ];
      
      const preloadImages = (imageArray) => {
        imageArray.forEach((src) => {
          const img = new Image();
          img.src = src;
        });
      };
      useEffect(() => {
        preloadImages(imagesToCache);
      }, [])
    return (
        <>
        </>
    )
}

export default CachedImages