import React, { useEffect, useRef } from 'react';
import { Text } from 'react-konva';
import Konva from 'konva';

const PlusIndicator = ({ x, y, onAnimationEnd }) => {
  const textRef = useRef();

  useEffect(() => {
    const tween = new Konva.Tween({
      node: textRef.current,
      duration: 0.05, // Длительность анимации в секундах
      opacity: 0,
      onFinish: () => {
        onAnimationEnd();
        tween.destroy(); // Очистка анимации при размонтировании

      },
    });

    tween.play();

    return () => tween.destroy();
  }, [onAnimationEnd]);

  return (
    <Text
      ref={textRef}
      text="+1"
      x={x}
      y={y}
      fontSize={24}
      fill="white"
      opacity={1} // Начальная непрозрачность
      align="center"
      fontFamily='MonBold'
    />
  );
};

export default PlusIndicator;
