import { makeAutoObservable } from "mobx";

export default class GameStore {
  time = 3000;
  coins = 0;
  heart = 0;
  isResetGame = false;
  isRoll = false;

  constructor() {
    makeAutoObservable(this);
  }

  // Getters
  get getTime() {
    return this.time;
  }

  get getCoins() {
    return this.coins;
  }

  get getHeart() {
    return this.heart;
  }

  get getIsResetGame() {
    return this.isResetGame;
  }

  get getIsRoll() {
    return this.isRoll;
  }

  // Setters
  set setTime(value) {
    this.time = value;
  }

  set setCoins(value) {
    this.coins = value;
  }

  set setHeart(value) {
    this.heart = value;
  }

  set setIsResetGame(value) {
    this.isResetGame = value;
  }

  set setIsRoll(value) {
    this.isRoll = value;
  }
}
