import { useEffect, useState } from "react";
import styles from "./style.module.css";

const Loading = ({isLoading}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) =>{
        if(prev >= 100) {
          return 100;
        }
        return prev + 3;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const getStyleCircle = () => {
    const circumference = 2 * Math.PI * 74; 
    const offset = circumference - (progress / 100) * circumference;
    return {
      strokeDasharray: `${circumference} ${circumference}`,
      strokeDashoffset: offset,
      transition: 'stroke-dashoffset 0.3s', 
    };
  };

  return (
    <div className={styles.main} >
      {
        
        <svg className={styles.loadCircle} viewBox="0 0 150 150">
          <circle
            style={getStyleCircle()}
            className={styles.procesing_ring}
            cx="75"
            cy="75"
            r="74" // Радиус 74 для соответствия размеру SVG
          />
        </svg>
       
      }
    </div>
  );
};

export default Loading;
