import {FC, useContext, useEffect, useRef, useState} from "react";

import { observer } from "mobx-react-lite";

const PlanetIcon = observer(({setGameState}) => {
        return (
            <>
                <img src="./assets/icons/Pluton.png"></img>
            </>
        )
});

export default PlanetIcon
