import {FC} from "react";

import styles from "./styles.module.css";
import LeagueList from "../../compotents/Pages/league/LeagueList/LeagueList";
import {LeagueSwiper} from "../../compotents/Pages/league/LeagueSwiper/LeagueSwiper";
import LeguageBanner from "../../compotents/Pages/league/LegueBanner/LegueBanner";


export const Leaders= () => {
    return (
        <div className={styles.main}>
            <h1 className={styles.title}>
                leaderboard
            </h1>
            
            <LeagueSwiper/>
            <LeguageBanner></LeguageBanner>
            <LeagueList/>
        </div>
    );
}

