class GameStateManager{
  
  constructor(initialState) {
    this._state = initialState;
    this.time = 3000;
    this.coins = 0;
    this.heart = 0;
    this.listeners = [];
    this.isResetGame = false;
    this.isRoll = false;
  }

  setState(newState) {
    if(newState === 0) {
      this._coins = 0;
    }
    this._state = newState;
    this.notifyListeners();
  }

  setRestGame(bool) {
    this.isResetGame = bool;
  }

  setCoins(int) {
    this._coins = int;
  }
  
  getCoins() {
    return this._coins;
  }

  get state() {
    return this._state;
  }

  gameLoopRestart() {
    this.setState(this._initialState);
    this._coins = 0;
  }
  subscribe(listener) {
    this.listeners.push(listener);
  }

  // Метод для отписки от изменений состояния
  unsubscribe(listener) {
      this.listeners = this.listeners.filter(l => l !== listener);
  }
  notifyListeners() {
    this.listeners.forEach(listener => listener(this.state));
}
}
const GameState = new GameStateManager(1)
export default GameState;