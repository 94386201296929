import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Stage, Layer } from 'react-konva';
import BackgroundImage from '../../game/entites/visual/BackgroundImage/BackgroundImage';
import PlatformImage from '../../game/entites/visual/PlatformImage/PlatformImage';
import BallImage from '../../game/entites/player/BallImage';
import BonusImage from '../../game/entites/bonuses/BonusImage';
import TimeCounter from '../../game/counters/TimeCounter';
import HeartCounter from '../../game/counters/HeartCounter';
import CoinCounter from '../../game/counters/CoinCounter';
import PlusIndicator from '../../game/gui/PlusIndicator';
import LoaderCircle from '../../game/gui/loader/loader';
import GameState from '../../game/state/gameManager';
import { Context } from '../..';
import { setCoinsUser } from '../../http/userAPI';



const BONUS_IMAGE_PATHS = [
  '../../assets/sprites/Dice1.png',
  '../../assets/sprites/Heart2.png',
  '../../assets/sprites/Coin2.png',
];


const Game = ({setState, state, setGameState}) => {
  const [y, setY] = useState(window.innerHeight / 2 + 105);
  const [velocity, setVelocity] = useState(0);
  const [bonuses, setBonuses] = useState([]);
  const [bonusImages, setBonusImages] = useState([]);
  const [time, setTime] = useState(0);
  const [hearts, setHearts] = useState(0);
  const [coins, setCoins] = useState(0);
  const [plusIndicators, setPlusIndicators] = useState([]);
  const [diceTime, setDiceTime] = useState(Math.floor(Math.random() * 3001));
  const [isEnd, setIsEnd] = useState(true);
  const [isTakeDice, setIsTakeDice] = useState(false);
  const [isRest, setIsReset] = useState(false);
  const [isControll, setIsControll] = useState(false);
  const [arrHearts, setArrHearts] = useState([]);
  const gravity = 0.7;
  const minJumpStrength = -5;
  const maxJumpStrength = -15;
  const bounceFactor = 0.4;
  const tapBoostInterval = 200;
  let timeStart;

  const {user} = useContext(Context);


  useEffect(() => {
    const loadBonusImages = async () => {
      const loadedImages = await Promise.all(
        BONUS_IMAGE_PATHS.map(path => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = path;
            img.onload = () => resolve(img);
            img.onerror = () => resolve(null);
          });
        })
      );
      setBonusImages(loadedImages.filter(img => img !== null));
    };

    loadBonusImages();
  }, []);

  function setHeartsCount() {
  
    setDiceTime(Math.floor(Math.random() * 31));

    const HeartsCount = Math.floor(Math.random() * 3) + 1;;
  
    const newHearts = [];
  
   for (let i = 0; i < HeartsCount; i++) {
     newHearts.push(Math.floor(Math.random() * 28));
   }

   setArrHearts(newHearts.sort((a, b) => a - b));
  }

  useEffect(() => {
    setIsReset(GameState.isResetGame);
    if(!GameState.isResetGame && isControll) {
    
      setY(window.innerHeight / 2 + 1 * 200 - 25 * 1);
    }

    if(GameState.state === 0) {
      GameState.isRoll = false;
      setIsTakeDice(false);
      setIsControll(true);
      setCoins(0);
      setDiceTime(Math.floor(Math.random() * 2801));
      

      GameState.setCoins(0);
      setCoins(0);
      setHearts(user.heart);
    }
    

    if(isControll) {
      
      
    }
    
    setTime(0);
    timeStart = Date.now();
    
    setPlusIndicators([]);
    setBonuses([]);    
    setHeartsCount();


   

  }, [GameState.state]);

  useEffect(() => {
    if(Math.floor(time) === 40) {
      setVelocity(1);
    }
    // if(Math.floor(time) === 270) {
    //   setIsControll(true);
    // }
    if(time >= GameState.time) {
      
      setPlusIndicators([]);
      setBonuses([]);
      setIsControll(false);
    }

    if(diceTime * 100 === Math.floor(time)) {
      
      setIntervalBonuses(0);
    }

       setCheckHearts();
  
  }, [time]);


  useEffect(() => {
    const downBorder = 78;
    if(!isControll && y >= window.innerHeight - 80) {
      setState(2);
      GameState.setState(2);
      setGameState(2);
      console.log(coins);
      GameState.coins = coins;
      user.setHeart(hearts);

    }
  }, [isControll, y]);

  let tapsCount;


  const handleJump = useCallback((e) => {
    if (GameState.state !== 0 && tapsCount > 4) return;
    if(!isControll) return;
    tapsCount++;
    window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('medium');

    const currentTime = Date.now();
    const timeSinceLastJump = currentTime - (window.lastJumpTime || 0);
    const jumpStrength = timeSinceLastJump < tapBoostInterval
      ? Math.max(maxJumpStrength, velocity + minJumpStrength)
      : minJumpStrength;

    setVelocity(jumpStrength);
    window.lastJumpTime = currentTime;

    setCoins(prevCoins => prevCoins + 1);

    const pointerPosition = e.target.getStage().getPointerPosition();
    setPlusIndicators(prevIndicators => [
      ...prevIndicators,
      { x: pointerPosition.x, y: pointerPosition.y }
    ]);
  }, [velocity]);


  useEffect(() => {
    let interval;

    if (GameState.state === 0) {
      interval = setInterval(() => {

          setTime((Date.now() - timeStart)/10);
          
          if(time >= 2000) {
            setVelocity(1);
          }
        
      }, 10);

      return () => clearInterval(interval);
    }
  }, [GameState.state]);
  

  useEffect(() => {
    let interval;

    if (GameState.state !== 1) {
      tapsCount++;
      // Обновление состояния игры
      interval = setInterval(() => {
        setY(prevY => {
          const downBorder = 78;
          const newY = prevY + velocity;
          if (Math.abs(velocity) * bounceFactor < 0.5 && window.innerHeight - newY <= downBorder) {
            return window.innerHeight - downBorder;
          }
          if (newY >= window.innerHeight - downBorder - 1) {
            setVelocity(-Math.abs(velocity) * bounceFactor);
           
            return window.innerHeight - downBorder;
          }
          if (newY <= 45) {
            setVelocity(Math.abs(velocity) * bounceFactor);
            return 45;
          }
          return newY;
        });

        setVelocity(prevVelocity => prevVelocity + gravity);
      }, 20);

      return () => clearInterval(interval);
    }
  }, [velocity]);

  const bonusIntervalRef = useRef(null);

  function setIntervalBonuses(currentType = null) {
    if(document.hidden) return;
    const side = Math.random() > 0.5 ? 1 : -1;
        const x = side === 1 ? window.innerWidth + 80 : -80;
        const y = 100 + Math.random() * (window.innerHeight - 200);

        const randomValue = Math.random();
        let type = currentType != null ? currentType : randomValue < 0.60 ? 2 : 2; // Тип 2 - монеты, Тип 1 - сердечки
        const speed = 1;
        
        setBonuses(prevBonuses => [
          ...prevBonuses,
          { x, y, type, direction: side, speed }
        ]);
  }

  useEffect(() => {
    if (GameState.state === 0 ) {
      bonusIntervalRef.current = setInterval(() => {

        setIntervalBonuses();
      }, 1000);

      return () => clearInterval(bonusIntervalRef.current);
    }
  }, [state, GameState.state]);

  useEffect(() => {
    let interval;

    if (GameState.state === 0) {
      
      interval = setInterval(() => {
        setBonuses(prevBonuses => prevBonuses
          .map(bonus => ({
            ...bonus,
            x: bonus.x - (bonus.direction * 5 * bonus.speed)
          }))
          .filter(bonus => !(bonus.direction === 1
            ? bonus.x < -80
            : bonus.x > window.innerWidth + 80)));
      }, 20);

      return () => clearInterval(interval);
    }
  }, [GameState.state]);

  function setCheckHearts() {
  
    arrHearts.forEach((el) => {

      if(time/100 === el) {
        setIntervalBonuses(1);
      }
    })
  }

  useEffect(() => {
    let interval;
    const takeBonusRadius = 45;
    if (GameState.state === 0) {
 
      // Проверка столкновений
      interval = setInterval(() => {
        setBonuses(prevBonuses => prevBonuses.filter(bonus => {
          const collision = (
            window.innerWidth / 2 - 25 < bonus.x + takeBonusRadius &&
            window.innerWidth / 2 - 25 + takeBonusRadius > bonus.x &&
            y < bonus.y + takeBonusRadius &&
            y + takeBonusRadius > bonus.y
          );

          if (collision) {
            bonus.isDestroy = true;
            
            if (bonus.type === 0) takeDice();window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('heavy');;
            if (bonus.type === 1) setHearts(prevHearts => prevHearts + 1);window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('medium');;
            if (bonus.type === 2) {setCoins(prevCoins => prevCoins + 100);window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('light');};
            console.log(bonus.type)
            return false;
          }

          return true;
        }));
        
      }, 20);

      return () => clearInterval(interval);
    }
  }, [y]);

  function takeDice() {
    setIsTakeDice(true);
    GameState.isRoll = true;
  }

  useEffect(() => {
    console.log(GameState.isRoll, 'isRoll')
  }, [GameState.isRoll])

  useEffect(() => {
    let interval;

    if (GameState.state === 0 && isControll) {
      // Удаление PlusIndicator после анимации
      interval = setInterval(() => {
        setPlusIndicators(prevIndicators => prevIndicators.slice(1));
      }, 750);

      return () => clearInterval(interval);
    }
  }, []);
  // useEffect(() => {

  //       if( 0 >= GameState.time - time) {
  //         GameState.setState(2);
  //         setState(2)
  //       }
  // }, [time]);

  


  return (
    <Stage
      width={window.innerWidth}
      height={window.innerHeight}
      onTouchStart={handleJump}
      onMouseDown={handleJump}
    >

      <Layer>
      <BackgroundImage />
        {
          GameState.state !== 1 && GameState.state !==5 && 
          <>
          <PlatformImage />
          </>
         
        }
        { (GameState.state !== 1 || GameState.state !== 5) &&
           <LoaderCircle
            x={window.innerWidth / 2}
            y={window.innerHeight / 2 * 1.015 + 0}
            radius={window.innerWidth / 3.1}
            percentage={time / 30}
            borderColor="#ffffff00"
            fillColor="#5297ff35"
            strokeWidth={window.innerWidth / 24}
          /> 
        }
        {GameState.state === 0 && GameState.state !==5 && isControll &&  (
          <>
            <TimeCounter isTakeDice={isTakeDice} time={((GameState.time - time) / 100).toFixed(1)} />
            <HeartCounter hearts={hearts} />
            <CoinCounter coins={coins} />
            {bonuses.map((bonus, index) => (
              <BonusImage type={bonus.type} key={index} isDestroy={bonus.isDestroy} image={bonusImages[bonus.type]} x={bonus.x} y={bonus.y} />
            ))}
            {plusIndicators.map((indicator, index) => (
              <PlusIndicator
                key={index}
                x={indicator.x}
                y={indicator.y}
                onAnimationEnd={() => {
                  setPlusIndicators(prevIndicators => prevIndicators.filter((_, i) => i !== index));
                }}
              />
            ))}
          </>
        )}
      </Layer>
      <Layer>
        {
          GameState.state !== 1 && GameState.state !==5 &&  <BallImage isEnd={isEnd} setIsEnd={setIsEnd} y={y} isAnimation={!isRest} />
        }
        {/* {
          GameState.state === 2 &&  <BallImage y={window.innerHeight - 80} isAnimation={false} />
        } */}
       
      </Layer>
      
    </Stage>
  );
};

export default Game;