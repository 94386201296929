import React from 'react';
import { Group, Arc } from 'react-konva';
import Konva from 'konva';

const LoaderCircle = ({ x, y, radius, percentage, borderColor, fillColor, strokeWidth }) => {
  // Проверяем, что процент находится в диапазоне от 0 до 100
  const clampedPercentage = Math.max(0, Math.min(percentage, 100));

  // Вычисляем угол заполнения в градусах
  const angle = (clampedPercentage / 100) * 360;

  return (
    <Group x={x} y={y}>
      {/* Пустой круг (граница) */}
      <Arc
        x={0}
        y={0}
        innerRadius={radius - strokeWidth / 2}
        outerRadius={radius}
        angle={360}
        stroke={borderColor}
        strokeWidth={strokeWidth}
        fill="transparent"
        lineJoin="round"
        shadowBlur={20} // Применяем эффект размытия как альтернатива фильтру
        shadowColor="rgba(0, 0, 0, 0.5)" // Цвет тени
        scaleX={0.5}
      />

      {/* Заполненный круг */}
      <Arc
        x={-1}
        y={0}
        innerRadius={radius - strokeWidth / 2 -1}
        outerRadius={radius}
        angle={angle}
        stroke="transparent"
        fill={fillColor}
        strokeWidth={strokeWidth}
        rotation={-90} 
        lineJoin="round"
        shadowColor="#5297ff"
        shadowBlur={5}
        shadowOffsetX={0}
        shadowOffsetY={0}

        scaleY={0.979}
        scaleX={0.97}
      />
    </Group>
  );
};

export default LoaderCircle;
